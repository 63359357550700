<template>
    <Layout buy-button-class="btn-default btn-small" :showTopHeader="showTopHeader">
        <div>
            <button :class="['draggable', showTopHeader ? 'draggable-button' : 'draggable-button1']" @click="change">
                <img :src="iconsrc" alt="">
            </button>
            <img v-if="!showTopHeader" :src="iconsrc2" :class="[showTopHeader ? 'draggable-button3' : 'draggable-button2']" alt="">
        </div>
        <!-- Start Counterup Area -->
        <div class="our-counterup-area">
            <div :class="[showTopHeader ? 'container' : '']">
                <div class="row">
                    <!-- <div class="col-lg-12"> -->
                        <!-- <div class="thumbnail" data-aos="fade-up"> -->
                            <!-- <img class="radius w-100" src="../../assets/images/bg/bg-image-21.jpg" alt="Images"/> -->
                            <!-- <iframe ref="myIframe" :src="datajson[$route.query.id].iframurl" frameborder="0"
                            :style="{ height: showTopHeader ? '600px' : iframeHeight + 'px'}"></iframe> -->
                            <iframe ref="myIframe" :src="datajson[$route.query.id].iframurl||datajson[$route.query.id].submenu[$route.query.idsub].iframurl" frameborder="0"
                            :style="{ height: iframeHeight + 'px'}"></iframe>
                        </div>
                    <!-- </div> -->
                <!-- </div> -->
            </div>
        </div>
        <!-- End Counterup Area -->
    </Layout>
</template>

<script>
import Layout from "../../components/common/Layout";
import iframejson from "../../assets/json/menus.json"

export default {
    name: 'Iframe',
    components: { Layout},
    data() {
        return {
            datajson: iframejson,
            iconsrc: require('@/assets/images/icons/max.png'),
            iconsrc2: require('@/assets/images/logo/yz-logo.png'),
            big: false,
            count: 1,
            showTopHeader: true,
            viewportHeight:window.innerHeight,
            iframeHeight:600
        }
    },
    methods: {
        change() {
            this.count++;
            if (this.count % 2 == 0) {
                this.iconsrc = require('@/assets/images/icons/min.png')
                this.showTopHeader = false
            }
            else {
                this.iconsrc = require('@/assets/images/icons/max.png')
                this.showTopHeader = true
            }
        },
        calHeight() {
            this.iframeHeight = this.viewportHeight - (this.showTopHeader?110:0)
        },
        handleResize() {
            this.viewportHeight = window.innerHeight;
        }
    },
    watch:{

        viewportHeight(newValue){
            this.iframeHeight = newValue - (this.showTopHeader?110:0)
        }
    },
    mounted() {
        this.$refs.myIframe.addEventListener('load', () => {
            console.log('iframe加载完成');
            const iframeContents = this.$refs.myIframe.contentWindow.document; // 获取iframe内部的文档
            const headerElement = iframeContents.getElementById('header');
            const navUtilityElement = iframeContents.getElementById('nav_utility');
            const iframeUrl = this.$refs.myIframe.getAttribute('src');

            if (headerElement) {
                headerElement.style.display = 'none';
            }

            if (navUtilityElement) {
                navUtilityElement.style.display = 'none';
            }
            if (iframeUrl.endsWith('index.htm')) {
                const frameElement = iframeContents.getElementById('frame');
                if (frameElement) {
                    frameElement.style.backgroundColor = '#fff';
                }
            }
        });
    //    this.loginToQlik()
       this.calHeight()
       window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>
<style scoped>
.draggable {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    z-index: 1111;
    background-color: #FB5343;
}

.draggable-button {
    position: relative;
    top: -60px;
    left: 95%;
    margin-left: 15px;
}

.draggable-button1 {
    position: relative;
    top: 0px;
    left: 93vw;
}

.draggable-button2 {
    position: absolute;
    top: 1vh;
    left: 8vw;
    margin: auto;
    height: 25px;
}

.draggable-button3 {
    display: none;
}

.draggable-button img,
.draggable-button1 img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0;
    vertical-align: middle;
    max-width: 50%;
    height: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .draggable-button {
        position: relative;
        top: -60px;
        left: 90%;
        margin-left: 15px;
    }
    .draggable-button1 {
        position: relative;
        top: 0px;
        left: 90vw;
    }
}
@media only screen and (min-width: 589px) and (max-width: 767px){
    .draggable-button {
        position: relative;
        top: -60px;
        left: 89%;
        margin-left: 15px;
    }
    .draggable-button1 {
        position: relative;
        top: 0px;
        left: 90vw;
    }
}

@media only screen and (max-width: 588px){
    .draggable-button {
        position: relative;
        top: -60px;
        left: 55%;
        margin-left: 15px;
    }
    .draggable-button1 {
        position: relative;
        top: 0px;
        left: 85vw;
    }
}

</style>
  