<template>
    <Layout buy-button-class="btn-default btn-small" :showTopHeader="showTopHeader">
        <div :style="{ height: showTopHeader ? '0' : '40px'}">
            <button :class="['draggable', showTopHeader ? 'draggable-button' : 'draggable-button1']" @click="change">
                <img :src="showTopHeader ? iconsrcMax : iconsrcMin" alt="">
            </button>
            <img v-if="!showTopHeader" :src="iconsrc2" class="draggable-button2" alt="">
        </div>
        <!-- Start Counterup Area -->
        <div class="our-counterup-area">
            <div :class="[showTopHeader ? 'container' : '']">
                <div style="display: flex;width:100%;flexWrap:wrap;justify-content:center;display: none;">
                    <div class="custom-control custom-radio custom-control-inline easitem"
                        v-for="(item, index) in radioArray" :key="index" ref="myinput"
                        style="margin: 5px;border-radius:5px;padding:5px;background-image: linear-gradient(to top right, #cbeef6 0%, #f1d9e3 100%);">
                        <input type="radio" :id="`customRadioInline${index + 1}`" name="customRadioInline"
                            class="custom-control-input input1" :value="index" v-model="selectedItemIndex"
                            @change="updateIframeSrc">
                        <label class="custom-control-label" :for="`customRadioInline${index + 1}`">{{ item.title }}</label>
                    </div>
                    <div class="btn-group">
                        <button type="button" class="btn btn-info dropdown-toggle btn1" data-bs-toggle="dropdown"
                            aria-expanded="false" >
                            {{ selectedTitle || listArray[0].title+'-'+listArray[listArray.length-1].title }}
                        </button>

                        <ul class="dropdown-menu" style="max-height: 400px; overflow-y: auto;">
                            <li v-for="(item, index) in listArray" :key="index"><a class="dropdown-item" href="#"
                                    @click="selectItem(item)">{{ item.title }}</a></li>
                        </ul>
                    </div>
                </div>
                    <iframe ref="myIframe" :src="easurl" frameborder="0"
                    :style="{ height: iframeHeight + 'px'}"></iframe>
                    <div>
                        
                    </div>
            </div>
        </div>
        <!-- End Counterup Area -->

    </Layout>
</template>

<script>
import Layout from "../../components/common/Layout";
import iframejson from "../../assets/json/menus.json"

export default {
    name: 'Eas',
    components: { Layout },
    data() {
        return {
            datajson: iframejson,
            iconsrcMax: require('@/assets/images/icons/max.png'),
            iconsrcMin: require('@/assets/images/icons/min.png'),
            iconsrc2: require('@/assets/images/logo/yz-logo.png'),
            big: false,
            count: 1,
            showTopHeader: true,
            viewportHeight: window.innerHeight,
            viewportWidth:window.innerWidth,
            iframeHeight:600,
            selectedItemIndex: 0,
            easurl:"/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.ALL.qvw",
            easArray:[{id:'0',title:'ALL',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.ALL.qvw'},
            {id:'1',title:'L01',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L1.qvw'},
            {id:'2',title:'L02',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L2.qvw'},
            {id:'3',title:'L03',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L3.qvw'},
            {id:'4',title:'L04',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L4.qvw'},
            {id:'5',title:'L05',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L5.qvw'},
            {id:'6',title:'L06',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L6.qvw'},
            {id:'7',title:'L07',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L7.qvw'},
            {id:'8',title:'L08',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L8.qvw'},
            {id:'9',title:'L09',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L9.qvw'},
            {id:'10',title:'L10',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L10.qvw'},
            {id:'11',title:'L11',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L11.qvw'},
            {id:'12',title:'L12',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L12.qvw'},
            {id:'13',title:'L13',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L13.qvw'},
            {id:'14',title:'L14',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L14.qvw'},
            {id:'15',title:'L15',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L15.qvw'},
            {id:'16',title:'L16',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L16.qvw'},
            {id:'17',title:'L17',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L17.qvw'},
            {id:'18',title:'L18',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L18.qvw'},
            {id:'19',title:'L19',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L19.qvw'},
            {id:'20',title:'L20',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L20.qvw'},
            {id:'21',title:'L21',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L21.qvw'},
            {id:'22',title:'L22',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L22.qvw'},
            {id:'23',title:'L23',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L23.qvw'},
            {id:'24',title:'L24',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L24.qvw'},
            {id:'25',title:'L25',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L25.qvw'},
            {id:'26',title:'L26',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L26.qvw'},
            {id:'27',title:'L27',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L27.qvw'},
            {id:'28',title:'L28',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L28.qvw'},
            {id:'29',title:'L29',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L29.qvw'},
            {id:'30',title:'L30',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L30.qvw'},
            {id:'31',title:'L31',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L31.qvw'},
            {id:'32',title:'L32',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L32.qvw'},
            {id:'33',title:'L33',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L33.qvw'},
            {id:'34',title:'L34',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L34.qvw'},
            {id:'35',title:'L35',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L35.qvw'},
            {id:'36',title:'L36',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L36.qvw'},
            {id:'37',title:'L37',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L37.qvw'},
            {id:'38',title:'L38',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L38.qvw'},
            {id:'39',title:'L39',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L39.qvw'},
            {id:'40',title:'L40',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L40.qvw'},
            {id:'41',title:'L41',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L41.qvw'},
            {id:'42',title:'L42',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L42.qvw'},
            {id:'43',title:'L43',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L43.qvw'},
            {id:'44',title:'L44',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L44.qvw'},
            {id:'45',title:'L45',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L45.qvw'},
            {id:'46',title:'L46',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L46.qvw'},
            {id:'47',title:'L47',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L47.qvw'},
            {id:'48',title:'L48',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L48.qvw'},
            {id:'49',title:'L49',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L49.qvw'},
            {id:'50',title:'L50',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L50.qvw'},
            {id:'51',title:'L51',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L51.qvw'},
            {id:'52',title:'L52',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L52.qvw'},
            {id:'53',title:'L53',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L53.qvw'},
            {id:'54',title:'L54',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L54.qvw'},
            {id:'55',title:'L55',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L55.qvw'},
            {id:'56',title:'L56',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L56.qvw'},
            {id:'57',title:'L57',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L57.qvw'},
            {id:'58',title:'L58',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L58.qvw'},
            {id:'59',title:'L59',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L59.qvw'},
            {id:'60',title:'L60',easurl:'/QvAJAXZfc/opendoc.htm?document=eas-sic%2FEAS-SIC_beta.L60.qvw'}],
            length: 17,
            selectedTitle: null,
            flag: false
        }
    },
    methods: {
        change() {
            this.count++;
            if (this.count % 2 == 0) {
                this.showTopHeader = false
            }
            else {
                this.showTopHeader = true
            }
            this.calHeight();
        },

        calHeight() {
            this.iframeHeight = this.viewportHeight - (this.showTopHeader?160:100)
        },

        updateIframeSrc() {
            if (this.selectedItemIndex !== null) {
                this.easurl = this.easArray[this.selectedItemIndex].easurl;
            }
            console.log(this.easurl)
        },
        selectItem(item) {
            this.selectedItemIndex = null;
            // 当选择项时更新selectedTitle
            this.selectedTitle = item.title;
            this.easurl = item.easurl;
        },
        handleResize() {
            // 更新 viewportWidth
            this.viewportWidth = window.innerWidth;
            this.viewportHeight = window.innerHeight;
        }
    },
    watch:{
        viewportWidth(newValue){
            this.length=newValue*0.6/58
        },
        viewportHeight(newValue){
            this.iframeHeight = newValue - (this.showTopHeader?160:100)
        }
    },
    mounted() {
        if(this.viewportWidth<500){
            this.length=4
        }
        if(this.viewportWidth<380){
            this.length=3
        }
        
        this.calHeight()
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        radioArray() {
            return this.easArray.slice(0, this.length);
        },
        listArray() {
            return this.easArray.slice(this.length, this.easArray.length);
        }
    }
}
</script>
<style scoped>
.btn1{
   max-height:37px ;
   margin-top: 5px;
   background-image: linear-gradient(to top right, #cbeef6 0%, #fff, #f1d9e3 100%);
   font-size: 15px;
   color: #65676b;
}
.draggable {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    z-index: 1111;
    background-color: #FB5343;
}

.draggable-button {
    position: relative;
    top: -60px;
    left: 95%;
    margin-left: 15px;
}

.draggable-button1 {
    position: relative;
    top: 0px;
    left: 93vw;
}

.draggable-button2 {
    position: absolute;
    top: 1vh;
    left: 8vw;
    margin: auto;
    height: 25px;
}

.draggable-button3 {
    display: none;
}

.draggable-button img,
.draggable-button1 img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0;
    vertical-align: middle;
    max-width: 50%;
    height: auto;
}

.iframe_con{
    position: relative; 
    width:100%;
}
.input1{
    max-width: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px){
    .draggable-button {
        position: relative;
        top: -60px;
        left: 90%;
        margin-left: 15px;
    }
    
    .draggable-button1 {
        position: relative;
        top: 0px;
        left: 90vw;
    }
}
@media only screen and (min-width: 589px) and (max-width: 767px){
    .draggable-button {
        position: relative;
        top: -60px;
        left: 89%;
        margin-left: 15px;
    }
    
    .draggable-button1 {
        position: relative;
        top: 0px;
        left: 90vw;
    }
}

@media only screen and (max-width: 588px){
    .draggable-button {
        position: relative;
        top: -60px;
        left: 55%;
        margin-left: 15px;
    }
    
    .draggable-button1 {
        position: relative;
        top: 0px;
        left: 85vw;
    }
}


</style>
  