<template>
    <div>
        <!-- Start Footer Area  -->
        <!-- <footer v-if="data.showFooter" class="rn-footer footer-style-default variation-two">
        
        </footer> -->
        <!-- End Footer Area  -->

        <Copyright v-if="data.showCopyright"/>

        <!-- <ScrollTop/> -->
    </div>
</template>

<script>
    import Copyright from './Copyright'
    import Button from '../../elements/button/Button'
    import Icon from '../../icon/Icon'
    import ScrollTop from './ScrollTop'
    import Logo from '../../elements/logo/Logo'
    import Newsletter from './Newsletter'

    export default {
        name: 'Footer',
        components: {Newsletter, Logo, ScrollTop, Icon, Button, Copyright},
        props: {
            data: {
                default: null
            }
        }
    }
</script>