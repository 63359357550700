<template>
        <div class="form-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-6" style="margin: auto;">
                        <div class="form-horizontal">
                            <span class="heading">欢迎登录SMAS</span>
                            <div class="form-group">
                                <input type="email" class="form-control" id="inputEmail3" placeholder="用户名" v-model="username" @keyup.enter="loginbtn">
                                <i class="" style="margin:auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                    </svg>
                                </i>
                            </div>
                            <div class="form-group help">
                                <input type="password" class="form-control" id="inputPassword3" placeholder="密码" v-model="password" @keyup.enter="loginbtn">
                                <i class="fa fa-lock">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
                                        <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
                                    </svg>
                                </i>
                            </div>
                            <div class="form-group">
                                <!-- <div class="main-checkbox">
                                    <input type="checkbox" value="None" id="checkbox1" name="check"/>
                                    <label for="checkbox1"></label>
                                </div>
                                <span class="text">记住我</span> -->
                                <button type="submit" class="btn btn-default" @click="loginbtn" >立刻登录</button>
                                <p class="error-message" v-if="error">{{ errorMessage }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </template>
  <script>
  import axios from 'axios'

  export default{
    name:"login",
    data(){
        return{
            username:"eas1",
            password:"yanzidemo",
            error:false,
            errorMessage: "",
        }
    },
    methods:{
        loginbtn(){
            axios.post('/cboard/login',{
                username:this.username,
                password:this.password
            },{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(response=>{
                console.log(response);
                if (!response.data.includes("Sign in to start your session")) {
                    const now = new Date();
                    const expirationTime = now.getTime();
                    localStorage.setItem("loggedIn", expirationTime);
                    let query = { id: this.$app.globalData['urlidx'] }
                    if(this.$app.globalData['urlidxsub']!=undefined){
                        query.idsub = this.$app.globalData['urlidxsub'];
                    }
                    this.$router.push({ path: this.$app.globalData['url'] || "/" , query: query});
                    this.$forceUpdate();
                } else {
                    this.errorMessage = "账号或者密码错误";
                    this.error=true
                }
            }),
            axios.post('/cboard/dashboard/getBoardList.do',{
            }).then(response=>{
                console.log(response);
            })
        },
        checkLoginExpiration() {
            const loggedInTimestamp = localStorage.getItem("loggedIn");
            if (loggedInTimestamp) {
                const expirationTime = loggedInTimestamp;
                if (new Date().getTime()-expirationTime> 8 * 60 * 60 * 1000) {
                    localStorage.removeItem("loggedIn");
                }
            }
        },
    },
    mounted(){
        this.checkLoginExpiration();
    },
  }
</script>
  <style scoped>
  .form-bg{
    padding: 10em 0;
    background: linear-gradient(#141e30, #243b55); 
    width: 100vw; 
    height: 100vh;
}
.form-horizontal{
    background: #fff;
    padding-bottom: 40px;
    border-radius: 15px;
    text-align: center;
}
.form-horizontal .heading{
    display: block;
    font-size: 35px;
    font-weight: 700;
    padding: 35px 0;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 30px;
}
.form-horizontal .form-group{
    width: 80%;
    padding: 0 40px;
    margin: 0 0 25px 0;
    position: relative;
    
}
.form-horizontal .form-control{
    background: #f0f0f0;
    border: none;
    border-radius: 20px;
    box-shadow: none;
    padding: 0 20px 0 45px;
    height: 40px;
    transition: all 0.3s ease 0s;
}
.form-horizontal .form-control:focus{
    background: #e0e0e0;
    box-shadow: none;
    outline: 0 none;
}
.form-horizontal .form-group i{
    position: absolute;
    top: 4px;
    left: 60px;
    font-size: 17px;
    color: #c8c8c8;
    transition : all 0.5s ease 0s;
}
.form-horizontal .form-control:focus + i{
    color: #00b4ef;
}

.form-horizontal .btn{
    font-size: 14px;
    color: #fff;
    background: #00b4ef;
    border-radius: 30px;
    border: none;
    text-transform: capitalize;
    transition: all 0.5s ease 0s;
}
.error-message{
    color: red;
}
@media only screen and (max-width: 479px){
    .form-horizontal .form-group{
        padding: 0 25px;
    }
    .form-horizontal .form-group i{
        left: 45px;
    }
    .form-horizontal .btn{
        padding: 0px 20px;
    }
}

</style>