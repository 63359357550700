<template>
    <!-- Start Copy Right Area  -->
    <div class="copyright-area copyright-style-one">
        <!-- <div class="container"> -->
            <!-- <div class="row align-items-center"> -->
           
                <div class="col-lg-12 col-md-4 col-sm-12 col-12" >
                    <div class="text-center">
                        <p class="copyright-text">© Copyright 衍梓智能. All Rights Reserved</p>
                    </div>
                </div>
            <!-- </div> -->
        <!-- </div> -->
    </div>
    <!-- End Copy Right Area  -->
</template>

<script>
    export default {
        name: 'Copyright'
    }
</script>