import Vue from 'vue'
import VueRouter from 'vue-router'
import Demo from '../views/Demo'
import Iframe from '../components/iframe/iframe'
import Eas from '../components/eas/Eas'
import login from '../components/login/Login'
import Expect from '../components/expect/expect'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Demo',
        component: Demo,
        meta: {
            title: '衍梓智能SMAS',
        },
    },
    {
        path: '/qlik',
        name: 'qlik',
        component: Iframe,
        meta: {
            title: '衍梓智能SMAS',
        },
    },
    {
        path: '/portal',
        name: 'portal',
        component: Iframe,
        meta: {
            title: '衍梓智能SMAS',
        },
    },
    {
        path: '/eas',
        name: 'eas',
        component: Eas,
        meta: {
            title: '衍梓智能SMAS',
        }
    },
    {
        path: '/login',
        name: 'login',
        component: login,
        meta: {
            title: '欢迎登陆衍梓智能SMAS',
        }
    },,
    {
        path: '/qas',
        name: 'QAS',
        component: Expect,
        meta: {
            title: '敬请期待',
        }
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
