<template>
    <Layout buy-button-class="btn-default btn-small">
        <div class="expectation inner text-center" :style="{ height: iframeHeight + 'px'}">
            敬请期待
        </div>
    </Layout>
</template>

<script>
import Layout from "../../components/common/Layout";

export default {
    name: 'Iframe',
    components: { Layout },
    data() {
        return {
            viewportHeight:window.innerHeight,
            iframeHeight:0
        }
    },
    methods: {
        calHeight(){
            this.iframeHeight=this.viewportHeight-80-24
        },
        handleResize() {
            this.viewportHeight = window.innerHeight;
        }

    },
    watch:{
        viewportHeight(newValue){
            this.iframeHeight = newValue-104
        }
    },
    mounted() {
       this.calHeight()
       window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style scoped>
    .expectation{
        padding: 250px 20px 300px;
        font-size: 80px;
        color: #00a0bd;
    }
    @media screen and (max-width:500px){
        .expectation{
            padding: 250px 20px;
            font-size: 80px;
            color: #00a0bd;
        }
    }
</style>
  