<template>
    <div :class="{'header-transparent-with-topbar': data.headerTransparency}">
        <!-- Start Header Top Area -->
        <!-- <div v-if="data.showTopHeader && data.topbarStyle === 1"
             class="header-top-news"
             :style="{'background-image': `url(${require(`@/assets/images/bg/top-banner.png`)})`}">
            <div class="wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner">
                                <div class="content">
                                    <span class="rn-badge">Limited Time Offer</span>
                                    <span class="news-text">Intro price. Get Doob for Big Sale -95% off.</span>
                                </div>
                                <div class="right-button">
                                    <a class="btn-read-more"
                                       href="#">
                                        <span>Purchase Now <Icon name="arrow-right"/></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="icon-close">
                <button class="close-button bgsection-activation"
                        @click.prevent="AppFunctions.addClass('.header-top-news', 'deactive')">
                    <Icon name="x"/>
                </button>
            </div>
        </div> -->
        <!-- End Header Top Area -->

        <div v-if="data.showTopHeader && data.topbarStyle === 2"
             class="header-top-bar">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-12 col-12">
                        <div class="header-left">
                            <p><a href="#">Get the most advanced template <Icon name="chevron-right"/></a></p>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-12 col-12">
                        <div class="header-right">
                            <div class="address-content">
                                <p><Icon name="map-pin"/><span>Alabama, USA</span></p>
                                <p><Icon name="phone"/><span><a href="#">+06 58 49 99 56</a></span></p>
                            </div>
                            <div class="social-icon-wrapper">
                                <ul class="social-icon social-default icon-naked">
                                    <li><a target="_blank" href="https://www.facebook.com/"><Icon name="facebook"/></a></li>
                                    <li><a target="_blank" href="https://www.twitter.com"><Icon name="twitter"/></a></li>
                                    <li><a target="_blank" href="https://www.instagram.com"><Icon name="instagram"/></a></li>
                                    <li><a target="_blank" href="https://www.linkedin.com"><Icon name="linkedin"/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Start Header Area -->
        <header :class="[`rn-header header-default header-not-transparent header-sticky ${data.headerClass}`]">
            <div class="container position-relative">
                <div class="row align-items-center row--0">
                    <template v-if="data.navAlignment === 'right'">
                        <div class="col-lg-3 col-md-6 col-4">
                            <Logo/>
                        </div>
                        <div class="col-lg-9 col-md-6 col-8 position-static">
                            <div class="header-right">
                                <nav class="mainmenu-nav d-none d-lg-block">
                                    <Nav/>
                                </nav>

                                <!-- Start Header Btn -->
                                <div class="header-btn">
                                    <!-- <a :class="data.buyButtonClass"
                                       href="#">
                                        BUY NOW
                                    </a> -->
                                </div>
                                <!-- End Header Btn  -->

                                <!-- Start Mobile-Menu-Bar -->
                                <div class="mobile-menu-bar ml--5 d-block d-lg-none">
                                    <div class="hamberger">
                                        <button class="hamberger-button"
                                                @click.prevent="AppFunctions.toggleClass('.popup-mobile-menu', 'active')">
                                            <Icon name="menu" size="21"/>
                                        </button>
                                    </div>
                                </div>
                                <!-- Start Mobile-Menu-Bar -->

                                <div v-if="data.showThemeSwitcherButton" id="my_switcher" class="my_switcher">
                                    <ul>
                                        <li>
                                            <a href="javascript: void(0);"
                                               @click.prevent="AppFunctions.toggleClass('body', 'active-light-mode')">
                                                <img class="light-icon"
                                                     src="../../../assets/images/icons/sun-01.svg"
                                                     alt="Sun images">
                                                <img class="dark-icon"
                                                     src="../../../assets/images/icons/vector.svg"
                                                     alt="Moon Images">
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="data.navAlignment === 'left'">
                        <div class="col-lg-9 col-md-6 col-4 position-static">
                            <div class="header-left d-flex">
                                <Logo/>
                                <nav class="mainmenu-nav d-none d-lg-block">
                                    <Nav/>
                                </nav>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-8">
                            <div class="header-right">
                                <!-- Start Header Btn -->
                                <!-- <div class="header-btn">
                                    <a :class="data.buyButtonClass"
                                       href="#">
                                        BUY NOW
                                    </a>
                                </div> -->
                                <!-- End Header Btn  -->

                                <!-- Start Mobile-Menu-Bar -->
                                <div class="mobile-menu-bar ml--5 d-block d-lg-none">
                                    <div class="hamberger">
                                        <button class="hamberger-button"
                                                @click.prevent="AppFunctions.toggleClass('.popup-mobile-menu', 'active')">
                                            <Icon name="menu" size="21"/>
                                        </button>
                                    </div>
                                </div>
                                <!-- Start Mobile-Menu-Bar -->

                                <div v-if="data.showThemeSwitcherButton" id="my_switcher" class="my_switcher">
                                    <ul>
                                        <li>
                                            <a href="javascript: void(0);"
                                               @click.prevent="AppFunctions.toggleClass('body', 'active-light-mode')">
                                                <img class="light-icon"
                                                     src="../../../assets/images/icons/sun-01.svg"
                                                     alt="Sun images">
                                                <img class="dark-icon"
                                                     src="../../../assets/images/icons/vector.svg"
                                                     alt="Moon Images">
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </header>
        <!-- End Header Area -->

        <!-- Start Mobile Menu -->
        <MobileMenu/>
        <!-- End Mobile Menu -->

        <!-- Start Theme Style -->
        <div>
            <div class="rn-gradient-circle"/>
            <div class="rn-gradient-circle theme-pink"/>
        </div>
        <!-- End Theme Style -->
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'
    import MobileMenu from './MobileMenu'
    import AppFunctions from '../../../helpers/AppFunctions'
    import Nav from './Nav'
    import Logo from '../../elements/logo/Logo'

    export default {
        name: 'Header',
        props: {
            data: {
                default: null,
            }
        },
        components: {Logo, Nav, MobileMenu, Icon},
        data() {
            return {
                AppFunctions
            }
        },
        methods: {
            toggleStickyHeader() {
                const scrolled = document.documentElement.scrollTop;
                if (scrolled > 100) {
                    AppFunctions.addClass('.header-default', 'sticky');
                } else if (scrolled <= 100) {
                    AppFunctions.removeClass('.header-default', 'sticky');
                }
            },
            qlik_login() {
            console.log('qlik is to log...');
            var starttime = new Date().getTime();
            fetch("/QvAJAXZfc/Authenticate.aspx?back=/qlikview/FormLogin.htm&opendocqs=%3Fdocument%3DReport%252FEAS-SIC.qvw%26host%3DQVS%2540172_17_0_17&vt=" + new Date().getTime(), {
                headers: {
                'accept': "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
                'accept-language': "zh-CN,zh;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
                'cache-control': "max-age=0",
                'content-type': "application/x-www-form-urlencoded",
                'sec-ch-ua': "\"Not/A)Brand\";v=\"99\", \"Microsoft Edge\";v=\"115\", \"Chromium\";v=\"115\"",
                'sec-ch-ua-mobile': "?0",
                'sec-ch-ua-platform': "\"Windows\"",
                'sec-fetch-dest': "document",
                'sec-fetch-mode': "navigate",
                'sec-fetch-site': "same-origin",
                'sec-fetch-user': "?1",
                'upgrade-insecure-requests': "1"
                },
                referrer: "/qlikview/FormLogin.htm?opendocqs=%3Fdocument%3DReport%252FEAS-SIC.qvw%26host%3DQVS%2540172_17_0_17",
                referrerPolicy: "strict-origin-when-cross-origin",
                body: "username=eas&password=000000",
                //body: "username=yz\\user01&password=ZLv5WLhLaTMar5S",
                method: "POST",
                mode: "cors",
                credentials: "include"
            }).then(function (response) {
                !sessionStorage || sessionStorage.setItem('qliklogged', new Date().getTime());
                !sessionStorage || sessionStorage.setItem('isQlikLogClear', true);
                console.log('qlik is logged.' + (new Date().getTime() - starttime));
                // if (window.location.pathname.endsWith('/qlik') || window.location.pathname.endsWith('/eas')) 
                    window.location.reload();
            });
        },
        is_qlik_login(relog_cbk) {
            fetch("/QvAJAXZfc/AccessPoint.aspx?mark=&platform=browser.chrome&dpi=96", {
                headers: {
                'accept': "*/*",
                'accept-language': "zh-CN,zh;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6",
                'content-type': "text/plain",
                'sec-ch-ua': "\"Not/A)Brand\";v=\"99\", \"Microsoft Edge\";v=\"115\", \"Chromium\";v=\"115\"",
                'sec-ch-ua-mobile': "?0",
                'sec-ch-ua-platform': "\"Windows\"",
                'sec-fetch-dest': "empty",
                'sec-fetch-mode': "cors",
                'sec-fetch-site': "same-origin",
                'x-requested-with': "XMLHttpRequest"
                },
                referrer: "/qlikview/index.htm",
                referrerPolicy: "strict-origin-when-cross-origin",
                body: "<update mark=\"\" stamp=\"\" cookie=\"true\" scope=\"Document\" ident=\"AccessPoint\" kind=\"AccessPoint\"><set name=\"AccessPoint.Update\" action=\"\" /><set name=\"Document\" add=\"mode;ie6false\" /></update>",
                method: "POST",
                mode: "cors",
                credentials: "include"
            }).then((response) => response.text())
                .then((data) => {
                    if (data.indexOf('<value name="Logout" mode="enabled" />') < 0) {
                        if (relog_cbk instanceof Function) relog_cbk();
                    } else {
                        console.log('qlik has already logged in')
                    }
                });
        },
        loginToQlik() {
            
            let qliklogged = !sessionStorage || sessionStorage.getItem('qliklogged') || 1
            let isQlikLogClear = !sessionStorage || sessionStorage.getItem('isQlikLogClear')
            if (!isQlikLogClear) {
                this.qlik_login();
            }else if(new Date().getTime() - qliklogged>30*60*1000){
                this.is_qlik_login(this.qlik_login);
            }
        },
        },
        created() {
            window.addEventListener('scroll', this.toggleStickyHeader);
            this.loginToQlik()
        },
        mounted() {
            this.toggleStickyHeader();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.toggleStickyHeader);
        }
    }
</script>