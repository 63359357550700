<template>
    <ul class="mainmenu">
        <li class="has-droupdown has-menu-child-item">
            <!-- <a href="/">
                首页
            </a> -->
            <a :class="'/'==$app.globalData['url']?'active router-link-active':''" href="javascript:;"  @click='menuclick("/")'>首页</a>
        </li>
        <li v-for="(data, index) in datajson" :key="index" :class="data.submenu?['has-droupdown', 'has-menu-child-item']:[]">
            <a v-if="!data.submenu" :class="(data.url==$route.path && $route.query.idsub==undefined)?'active router-link-active':''" href="javascript:;"  @click='menuclick(data.url, index)'>{{data.title}}</a> 
            <a v-if="data.submenu" :class="$route.query.id==index?'active router-link-active':''" href="javascript:;">{{data.title}}</a>           
            <ul v-if="data.submenu" class="submenu">
                <li v-for="(other,indexsub) in data.submenu" :key="indexsub">
                    <a v-if="other.newtab" :href="other.url" target="_blank">{{ other.title }}</a>
                    <a v-else :class="(other.url==$route.path && $route.query.idsub==indexsub)?'active router-link-active':''" href="javascript:;"  @click='menuclick(other.url, index, indexsub)'>{{ other.title }}</a>
                </li>                
            </ul>
        </li>
        <li v-if="NEED_LOGIN" class="has-droupdown has-menu-child-item">
            <!-- <a href="/">
                首页
            </a> -->
            <router-link to="/login" v-if="!isUserLoggedIn">登录</router-link>
            <a href="javascript:;" v-else @click='logout()'>退出登录</a>
            <!-- <router-link :to="isUserLoggedIn ?'/':'/login'" @click.native='logout()'>{{loginInfo}}</router-link> -->
        </li>
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    import menus from "../../../assets/json/menus.json"

    export default {
        name: 'Nav',
        components: {Icon},
        data(){
            return{
                datajson:menus,
                NEED_LOGIN:true,
                loginInfo:"登录",
                loggingout:false,
            }
        },
        methods:{
            logout(){
                localStorage.removeItem("loggedIn");
                this.loggingout = true;
                if(this.$router.currentRoute.path!="/"){
                    this.$app.globalData['url'] = "/";
                    this.$router.replace("/");
                }
                this.$bus.$emit("loggingoutevent", this.loggingout);
                this.$forceUpdate();
            },
            menuclick(url,index,indexsub){
                let link = this.isUserLoggedIn ? { path: url, query: { id: index,idsub: indexsub } } : '/login'
                if(this.$app.globalData['url']!=url || '/' == url){
                    this.$app.globalData['url'] = url;
                    this.$app.globalData['urlidx'] = index;
                    this.$app.globalData['urlidxsub'] = indexsub;
                    this.$router.push(link); 
                }else{
                    this.$router.replace(link); 
                }      
            }
        },
        computed: {
        isUserLoggedIn() {
            let diff = new Date().getTime() - localStorage.getItem("loggedIn")||0;
            return ( diff<= 8 * 60 * 60 * 1000  && !this.loggingout) || !this.NEED_LOGIN;
        }
    },
    
    }
</script>
<style scoped>


</style>